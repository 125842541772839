import React from 'react';
import md5 from 'md5';


const h = {}


/**
 * 
 * @param {JavaScript date} date 
 * @returns a human readable date in the format "MM/DD/YYYY"
 */
h.makeDateHR = (date) => {
    let months = date.getMonth() + 1;
    let days = date.getDate();
    let years = date.getFullYear();
    return months + '/' + days + '/' + years
}

/**
 * 
 * @param {JavaScript date} date 
 * @returns a human readable time in the format "0:00AM"
 */
h.getTimeHR = date => {
    date = new Date(date);
    let meridian = 'AM';
    let hours = date.getHours();
    let minutes = date.getMinutes();
    if (hours >= 12) meridian = 'PM';
    if (!hours) hours = 12;
    if (hours > 12){
        hours -= 12;
    }
    if (String(minutes).length === 1) minutes = `0${minutes}`
    return hours + ':' + minutes + meridian;
}

/**
 * @param {String | Number} num - A number (i.e. 1000000)
 * @returns String - Number with commas appended (i.e. 1,000,000)
 */
h.numberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

h.dolHR = (n) => {
    /**
     * Returns human readable dollar amount from mongo value which is stored in pennies
     */
    n = Number(n);
    n = (n / 100).toFixed(2);
    n = h.numberWithCommas(n);
    if (n === ('-0.00')){
        n = '0.00';
    }
    return n;
}

/**
 * 
 * @param {String} string 
 * @returns The first up to 99 characters of that string
 */
h.shortString = string => {
    string = String(string);
    if (string.length > 100) return string.substring(0, 99) + '...';
    else return string;
}

/**
 * 
 * @param {String} string 
 * @returns Boolean - Whether the string is a number.
 */
h.isNumeric = string => {
    if (typeof string != "string") return false 
    return !isNaN(string) && !isNaN(parseFloat(string))
}

/**
 * Fixes MDB bug in which labels on inputs with text input are not properly floated
 * Floats the labels
 */
h.floatLabels = () => setTimeout(() => [].slice.call(document.getElementsByClassName('form-control')).forEach(e => {
    if (e.value && !e.classList.contains('active')) e.classList.add('active');
}), 250);

/**
 * 
 * @param {File} file 
 * @returns md5 hash of the file
 */
h.getMD5 = file => new Promise((resolve, reject) => {
    fetch(URL.createObjectURL(file)).then((res)=> res.text().then(text => resolve(md5(text))).catch(err => {
        console.log(err);
        return reject(err);
    })).catch(err => {
        console.log(err);
        reject(err);
    })
});

/**
 * Executes a captcha challenge and generates a key a key
 * Will hang until connected to captcha servers
 */
h.getRecaptcha = reCaptchaProps => new Promise((resolve, reject) => {
    if (reCaptchaProps.executeRecaptcha) reCaptchaProps.executeRecaptcha().then(res => resolve(res)).catch(err => {
        console.log('captcha error', err);
        alert('Recaptcha error. Refresh the page and try again.');
        return reject();
    });
    else {
        alert('There was an error connecting to the Recaptcha servers. Refresh the page and try again.');
        return reject();
    }
});

/**
 * 
 * @param {Object} userInfo - Users document
 * @returns Boolean - Whether the user has Janny privileges
 */
h.checkJanny = userInfo => {
    if (userInfo && userInfo.role && ['Janny', 'Chadmin'].indexOf(userInfo.role) !== -1) return true;
    else return false;
}

/**
 * 
 * @param {Object} userInfo - Users document
 * @returns Boolean - Whether the user has Chadmin privileges
 */
h.checkChadmin = userInfo => {
    if (userInfo && userInfo.role && userInfo.role === 'Chadmin') return true;
    else return false;
}

/**
 * 
 * @param {String} code - Removal code
 * @returns Removal label
 */
h.getRemovedReason = code => {
    switch(code){
        case 'fed':
            return 'Terrorism/Fedposting';
        case 'porn':
            return 'Porn';
        case 'spam':
            return 'Spam';
        default:
            console.log(code);
            return 'Other';
    }
}

/**
 * 
 * @param {String} string 
 * @returns The first up to 100 characters of that string
 */
h.abbreviatedText = text => {
    text = String(text);
    if (text.length > 100) return text.substring(0, 100) + '...';
    else return text;
}

/**
 * 
 * @param {String} string 
 * @returns The first up to 1000 characters of that string
 */
h.longString = text => {
    text = String(text);
    if (text.length > 1000) return text.substring(0, 1000) + '...';
    else return text;
}

export default h;